import './posadvance.css'
import { FirstHeader, SecondHeader} from '../../Header/Headers'
import { LeftButtons} from '../../LeftButtons/ContactButtons'
import caracteristicasImage from '../../../../POS/pos-cajera-dimasoft.jpeg'
import ssDashboard from '../../../../POS/screenshot_dashboard.jpg'
import ssDashboardTwo from '../../../../POS/screenshot-dashboard-dimasoft.jpg'
import ssDashboardMovile from '../../../../POS/screenshot-dashboard-movile.png'
import ssPos from '../../../../POS/screenshot_pos.jpg'
import ssReportePos from '../../../../POS/report-pos.png'
import posAdvanceLogo from '../../../../POS/pos-advance-logo.png'

import { Contactenos } from '../../Contactenos/Contactenos'
import { useState } from 'react'

import { ScrollRestoration } from "react-router-dom";
import { TitleDescription } from '../../MetaTags/TitleDescriptions';

import { FaCheck,FaAngleRight } from "react-icons/fa";
import { LuFileBadge,LuUploadCloud } from "react-icons/lu";
import { Link as Scroll} from 'react-scroll'

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import puntoVenta from '../../../../Products/kit-punto-venta-dimasoft.webp'
import pcTodoEnUno from '../../../../Products/pc-todo-en-uno-dimasoft.webp'
import gavetaDinero from '../../../../Products/gaveta-de-dinero-dimasoft.webp'
import scanner from '../../../../Products/scanner-dimasoft.webp'
import cajaRollos from '../../../../Products/caja-rollos-termicos-dimasoft.webp'
import impresora from '../../../../Products/impresora-termica-dimasoft.webp'


export function POSAdvanceR(){
    return <div className='containerGComercial'>
        <TitleDescription title='POS Advance - Dimasoft' description='Softwarte de POS o sistema POS robusto, facil de usar y en versión Cloud. Software POS Chile para pymes y grandes empresas como Supermercados, Minimarket, Botillerias, Panaderias y Pastelerias. Punto de venta. Punto venta.'/>
        <FirstHeader/>
        <SecondHeader/>
        <LeftButtons/>
        <div className='gc-title-section'>
            <h1>POS Advance </h1>
            <img src={posAdvanceLogo} alt="logo POS Advance" className='pos-advance-logo' data-aos="zoom-in" loading='lazy'/>
        </div>
        <POSAdvance/>
        <ScrollRestoration />
    </div>
}

export function POSAdvance(){

    const [showCaracteristicas, setShowCaracteristicas] = useState(false)

    const showerCaracteristicas = ()=>{
        setShowCaracteristicas(!showCaracteristicas)
    }

    const caracteristicas = [
        'Fácil de operar para el usuario Cajero.',
        'Intuitivo, rápido y efectivo.',
        'Emisión de Cotizaciones.',
        'Emisión Ticket de Venta / Pre Venta.',
        'Emisión ilimitada de Boleta Electrónica y Boleta Exenta.',
        'Emisión ilimitada de Factura Electrónica / Guías de Despacho / Notas de Crédito.',
        'Sincronización de DTEs de Ventas con SII.',
        'Sincronización de Facturas de Compras desde SII.',
        'Manejo de Sucursales.',
        'Registro y Creación de Clientes.',
        'Registro y Creación de Proveedores.',
        'SKUs ilimitados',
        'Manejo Scanner o Lector de Codigos de Barras.',
        'Compatible con Lectura de etiquetas de Balanzas Digitales.',
        'Control de Stock (eficiente y riguroso), con cantidades enteras o con decimales.',
        'Precios por tramos.',
        'Manejo de productos exentos.',
        'Múltiples Impuestos (Ila, harinas - carnes).',
        'Múltiples cajas.',
        'Perfil / Acceso de usuarios con clave.',
        'Cajero / Caja.',
        'Apertura Caja.',
        'Ingreso / Egreso Caja.',
        'Cierre de Turno.',
        'Cierre de Jornada.',
        'Informes de Inventario.',
        'Informe de Reposición de Stock.',
        'Informes de Utilidades.',
        'Variados informes de gestión para el control de su negocio.',
        'Exporta a Excel todos los informes.',
        'Capacitación.',
        'Soporte permanente, vía Telefono Mesa Central, Chat, WhatsApp y Telegram'
    ]

    return <div className='gcomercial'>
        
        <div className='line gradient line-small'></div>
        <div className='pos-caracteristicas'>
            <div className='left-content'>
                <ul>{caracteristicas.map((item,index)=>(
                        (index<12||showCaracteristicas)?
                        <li key={index}><FaAngleRight className='checkIcon'/> {item}</li>:''
                        ))
                    }
                </ul>
                <button onClick={showerCaracteristicas}>Ver {showCaracteristicas?'menos':'mas'}</button>
            </div>
            <img src={caracteristicasImage} alt="Imagen gestion comercial" loading='lazy' />
        </div>
        <br />
        <div className='line gradient line-small' data-aos="fade-right"></div>
        <br /><br />
        <div className='pos-advance-content-flex'>
            <div className='pos-advance-content-60' data-aos="zoom-in">
                <img src={ssPos} alt="screenshot pos" className='img-in-desktop perspective-left'/>
            </div>
            <div className='pos-advance-content-40' data-aos="fade-right">
                <h3>SOFTWARE INTUITIVO</h3>
                <p>Nuestro software cuenta con una interfaz amigable y fácil de usar, te permite realizar tus tareas de manera rápida y eficiente, sin necesidad de largos periodos de aprendizaje, ofreciéndote una experiencia fluida y sin complicaciones.</p>
            </div>
        </div><br /><br />
        <br />

        <div className='line gradient line-small' data-aos="fade-right"></div>
        <br /><br />
        <div className='pos-advance-content-flex'>
            <div className='pos-advance-content-40' data-aos="fade-left">
                <h3>DESCUBRE TUS ESTADÍSTICAS</h3>
                <p>Clarifica y adapta tus datos con nuestro sistema. Examina tus métricas de manera eficiente y comparte tus hallazgos sin complicaciones. Crea gráficos personalizables y descárgalos en formatos populares como PNG, CSV y SVG.</p>
            </div>
            <div className='pos-advance-content-60' data-aos="zoom-in">
                <img src={ssDashboardTwo} alt="screenshot dashboard" className='img-in-web perspective-right'/>
            </div>
        </div><br /><br /><br /><br />
        <div className='line gradient line-small' data-aos="fade-right"></div>
        <br /><br />
        <div className='pos-advance-content-flex'>
            <div className='pos-advance-content-60' data-aos="zoom-in">
                <img src={ssDashboard} alt="screenshot dashboard" className='img-in-web perspective-left'/>
            </div>
            <div className='pos-advance-content-40' data-aos="fade-left">
                <h3>VISUALIZA TUS METRICAS</h3>
                <p>Visualiza y personaliza tus métricas con nuestro software. Genera gráficos adaptables y descárgalos en formatos populares como PNG, CSV y SVG. Analiza tus datos de manera efectiva y comparte fácilmente tus resultados.</p>
            </div>
        </div><br /><br />
        <br />
        <br />
        
        <div className='line gradient line-small' data-aos="fade-right"></div>
        <br />
        <div className='pos-advance-content-flex'>
            <div className='pos-advance-content-50' data-aos="fade-right">
                <h3>ALTA ACCESIBILIDAD</h3>
                <p>Accede a tus métricas desde cualquier lugar y en cualquier momento con nuestra plataforma, totalmente compatible con dispositivos móviles y de escritorio. Nuestro diseño responsive garantiza una experiencia uniforme en todos tus dispositivos.</p>
            </div>
            <div className='pos-advance-content-50' data-aos="zoom-in">
                <img src={ssDashboardMovile} alt="screenshot dashboard mobile" className='img-in-movile'/>
            </div>
        </div>
        <div className='line gradient line-small' data-aos="fade-right"></div>
        <br />

        <div className='pos-advance-content-flex'>
            <div className='pos-advance-content-60' data-aos="zoom-in">
                <img src={ssReportePos} alt="screenshot dashboard" className='img-in-desktop desktop-report perspective-left'/>
            </div>
            <div className='pos-advance-content-40' data-aos="fade-left">
                <h3>REPORTES PERSONALIZADOS</h3>
                <p>POS Advance también genera informes completos y personalizables para respaldar su análisis. Con unos pocos clics, podrás crear informes profesionales que comuniquen sus datos de manera clara y efectiva.</p>
            </div>
        </div><br /><br />
        <br />

        <div className='line gradient line-small' data-aos="fade-right"></div>
        <br />

        <div className='gc-content-cards-precio'>

            <div className="card-precio" data-aos="zoom-in">
                <div className="container-card-precio">
                    <h3 className='cp-title'><b>Versión Licencia</b></h3>
                    <p>Desde <span style={{color:"#005c0d"}}>22 UF</span> + Iva.</p>
                    <p>3 Usuarios</p> 
                    <p>( Pago único )</p> 
                    <p>No Requiere Internet Continua</p> 
                    <br />
                    <LuFileBadge className='license-icon'/>
                    <ul></ul>
                    <br />
                    <Scroll activeClass="active" to="contactenos" smooth={true} offset={-60} duration={500}>
                        <button>Mas información</button>
                    </Scroll>
                </div>
            </div>

            <div className="card-precio card-more-used" data-aos="zoom-in">
                <div className="container-card-precio">
                    <div className='more-used'>Más usado</div>
                    <h3 className='cp-title title-cloud'>Versión Cloud</h3> 
                    <p>Desde <span>0,7 UF</span> + Iva</p>
                    <p>2 Usuarios</p> 
                    <p>( Activación 5 UF + Iva. Pago Único )</p>
                    <p>( Facturación mensual )</p>
                    <br />
                    <LuUploadCloud className='cloud-icon'/>
                    <ul></ul>
                    <br />
                    <Scroll activeClass="active" to="contactenos" smooth={true} offset={-60} duration={500}>
                        <button>Mas información</button>
                    </Scroll>
                </div>
            </div>

        </div>

        
        <h2>Segmento de Negocios</h2>
        <div className='line gradient line-small' data-aos="fade-right"></div>
        <div className='pos-advance-content'>
            <div className='pos-advance-content-flex pos-advance-content-sm-flex'>
                <div className='pos-advance-content-30 pos-advance-content-sm-50' data-aos="fade-right">
                    <ul>
                        <li><FaCheck className='check-pos'/> Almacenes</li>
                        <li><FaCheck className='check-pos'/> Minimarkets</li>
                        <li><FaCheck className='check-pos'/> Supermercados</li>
                        <li><FaCheck className='check-pos'/> Botillerías</li>
                        <li><FaCheck className='check-pos'/> Panaderías</li>
                        <li><FaCheck className='check-pos'/> Amasanderías</li>
                        <li><FaCheck className='check-pos'/> Pastelerías</li>
                        
                    </ul>
                </div>
                <div className='pos-advance-content-30 pos-advance-content-sm-50' data-aos="fade-right">
                    <ul>
                        <li><FaCheck className='check-pos'/> Verdulerías</li>
                        <li><FaCheck className='check-pos'/> Fruterías</li>
                        <li><FaCheck className='check-pos'/> Carnicerías</li>
                        <li><FaCheck className='check-pos'/> Tabaquerías</li>
                        <li><FaCheck className='check-pos'/> Bazares</li>
                        <li><FaCheck className='check-pos'/> Librerías</li>
                        <li><FaCheck className='check-pos'/> Comida Rápida</li>
                        
                    </ul>
                </div>
                <div className='pos-advance-content-30 pos-advance-content-sm-50' data-aos="fade-right">
                    <ul>
                        <li><FaCheck className='check-pos'/> Food Trucks</li>
                        <li><FaCheck className='check-pos'/> Farmacias</li>
                        <li><FaCheck className='check-pos'/> Pet Shop</li>
                        <li><FaCheck className='check-pos'/> Artículos de Aseo</li>
                        <li><FaCheck className='check-pos'/> Pequeñas Ferrerías</li>
                        <li><FaCheck className='check-pos'/> Pequeñas Distribuidoras</li>
                        <li><FaCheck className='check-pos'/> Jugueterías</li>
                    </ul>
                </div>
            </div>
            <h4>Conversemos de su negocio y como le podemos ayudar...</h4>
        </div>

        <div className='line gradient line-small' data-aos="fade-right"></div>
        <CompatibleProducts />
        <br />

        <h2>Contáctenos</h2>
        <div className='line gradient line-small' data-aos="fade-right"></div>
        <div className='gc-content' name='contactenos'>
            <Contactenos/>
        </div>
    </div>
}

function CompatibleProducts(){
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 2400 },
            items: 7
        },
        desktop: {
            breakpoint: { max: 2400, min: 1200 },
            items: 4
        },
        tablet: {
            breakpoint: { max: 1200, min: 990 },
            items: 3
        },
        tablet_s: {
            breakpoint: { max: 990, min: 720 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 720, min: 530 },
            items: 1
        },
        mobile_s: {
            breakpoint: { max: 530, min: 0 },
            items: 1
        }
      };

    return <div className='related-product' data-aos="fade-left">
        <h2>Productos Compatibles</h2>
        <Carousel responsive={responsive} containerClass="carousel-container">
            <CardCompatibleProduct name="SCANNERS" image={scanner}/>
            <CardCompatibleProduct name="GAVETAS DE DINERO" image={gavetaDinero}/>
            <CardCompatibleProduct name="COMPUTADORES" image={pcTodoEnUno}/>
            <CardCompatibleProduct name="ROLLOS TERMICOS" image={cajaRollos}/>
            <CardCompatibleProduct name="IMPRESORAS" image={impresora}/>
            <CardCompatibleProduct name="Kit Punto de Venta POS" image={puntoVenta}/>
        </Carousel>
    </div>
}

function CardCompatibleProduct({name,image,url='https://tienda.dimasoft.cl'}){
    return <div className='card-product'>
        <img src={image} alt="producto-dimasoft" />
        <p>{name}</p>
        <a href={url} className='button-card-product'>Ver producto</a>
    </div>
}
