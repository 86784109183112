import './headers.css'
import { FaUser , FaPhoneAlt, FaEnvelope, FaBars, FaTimes } from 'react-icons/fa'
import nuevodimasoft from '../../../Dimasoft/logo-dimasoft.webp'
import { Link } from "react-router-dom";
import { useState, useEffect } from 'react';

export function FirstHeader(){

    return <div className='firstHeaderContainer'> 
        <div className="firstHeader">
            <a href="https://dashboard-webapp.prod.dimasoft.cl/" target='_blank' className='client-access-desk'><FaUser/> Acceso Clientes</a>
            <a href="tel:+56222051018" target='_blank'><FaPhoneAlt/> (+56) 22 205 1018</a>
            <a href="mailto:info@dimasoft.cl" target='_blank'><FaEnvelope/> info@dimasoft.cl</a>
        </div>
        <div className='line'></div>
    </div>    
}

export function SecondHeader({change=false}){
    
    const [scrollTop, setScrollTop] = useState(0);

    useEffect(() => {
        const handleScroll = (event) => {
        setScrollTop(window.scrollY);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
        window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    const [active, setActive] = useState(false)

    useEffect(() => {
        document.body.style.overflow = 'visible'
    }, []);

    
    const shower = ()=>{
        setActive(!active)
        !active?document.body.style.overflow = 'hidden':
            document.body.style.overflow = 'visible'
    }
    

    const [dropdownSoftware, setDropdownSoftware] = useState(false)
    const showDropdownSoftware = ()=>{
        setDropdownSoftware(!dropdownSoftware)
        dropdownServices?setDropdownServices(!dropdownServices):''
    }

    const [dropdownServices, setDropdownServices] = useState(false)
    const showDropdownServices = ()=>{
        setDropdownServices(!dropdownServices)
        dropdownSoftware?setDropdownSoftware(!dropdownSoftware):''
    }
    

    return <div className={scrollTop!=0 ?'containMenuHeader h-small':'containMenuHeader'}>
        <div className={change?'menuHeader bg-transp':'menuHeader'}>
            <a href="/" className={scrollTop!=0 ?'dimasoftButton d-b-small':'dimasoftButton'}>
                <img className='logoDimasoft' src={nuevodimasoft} alt="Dimasoft"/>
            </a>
            <button aria-label="mostrar menu" className='showMenu' onClick={shower}><FaBars/></button>
            <a href='https://dashboard-webapp.prod.dimasoft.cl/' target='_blank' className='client-access-mobil'><FaUser/><span> Accesso</span></a>
            {change && scrollTop>=520?
            <div className={scrollTop!=0 ?'navHeader-inicio nav-h-small':'navHeader-inicio'}>
                <Link to="/">Inicio</Link>
                <div style={{}} />
                <Link to="/acerca">Acerca</Link>
                <div className='dropdown d-d-software'>
                    <Link to="/category/software">Software</Link>
                    <div className="dropdown-content">
                        <Link to="/software-pos-advance-chile">POS Advance</Link>
                        <Link to="/erp-software-dimasoft-gestion-comercial-chile">Gestión Comercial v7.1</Link>
                        <Link to="/erp-software-dimasoft-gestion-ferreterias-chile">Gestión Ferreterias v7.1</Link>
                        <Link to="/software-contabilidad-dimasoft-chile/">Contabilidad v7.0</Link>
                        <Link to="/software-remuneraciones-dimasoft-chile">Remuneraciones v8.0</Link>
                        <Link to="/software-factura-electronica-dimasoft-chile">Factura Electrónica</Link>
                        <Link to="/boleta-electronica-dimasoft-chile">Boleta Electrónica</Link>
                        <Link to="/software-e-commerce-erp-chile">E-Commerce</Link>
                    </div>
                </div>
                <div className='dropdown d-d-software'>
                    <Link to="/category/servicios">Servicios</Link>
                    <div className="dropdown-content">
                        <Link to="/capacitaciones-dimasoft-chile">Capacitaciones</Link>
                        <Link to="/servicios-ti-dimasoft-chile">Servicios TI</Link>
                        <Link to="/servicio-contable-y-auditoria">Servicio Contable y Auditoria</Link>
                    </div>
                </div>
                <a href="https://tienda.dimasoft.cl/">Tienda</a>
                <Link to="/clientes-dimasoft">Clientes</Link>
                <Link to="/contacto">Contacto</Link>
            </div>:
            <div className={scrollTop!=0 ?'navHeader nav-h-small':'navHeader'}>
                <Link to="/">Inicio</Link>
                <Link to="/acerca">Acerca</Link>
                <div className='dropdown d-d-software'>
                    <Link to="/category/software">Software</Link>
                    <div className="dropdown-content">
                        <Link to="/software-pos-advance-chile">POS Advance</Link>
                        <Link to="/erp-software-dimasoft-gestion-comercial-chile">Gestión Comercial v7.1</Link>
                        <Link to="/erp-software-dimasoft-gestion-ferreterias-chile">Gestión Ferreterias v7.1</Link>
                        <Link to="/software-contabilidad-dimasoft-chile/">Contabilidad v7.0</Link>
                        <Link to="/software-remuneraciones-dimasoft-chile">Remuneraciones v8.0</Link>
                        <Link to="/software-factura-electronica-dimasoft-chile">Factura Electrónica</Link>
                        <Link to="/boleta-electronica-dimasoft-chile">Boleta Electrónica</Link>
                        <Link to="/software-e-commerce-erp-chile">E-Commerce</Link>
                    </div>
                </div>
                <div className='dropdown d-d-software'>
                    <Link to="/category/servicios">Servicios</Link>
                    <div className="dropdown-content">
                        <Link to="/capacitaciones-dimasoft-chile">Capacitaciones</Link>
                        <Link to="/servicios-ti-dimasoft-chile">Servicios TI</Link>
                        <Link to="/servicio-contable-y-auditoria">Servicio Contable y Auditoria</Link>
                    </div>
                </div>
                <a href="https://tienda.dimasoft.cl/">Tienda</a>
                <Link to="/clientes-dimasoft">Clientes</Link>
                <Link to="/contacto">Contacto</Link>
            </div>
            }
            
        </div>
        <div className={active?'bg-sidebar-block':'bg-sidebar-unlock'}></div>
        <div className={active?'sideBar sideBar-active':'sideBar'}>
            <button aria-label="Ocultar menu" className='hideNav' onClick={shower}><FaTimes/></button>
            <Link to="/">Inicio</Link>
            <Link to="/acerca">Acerca</Link>
            <div className='dropdown-sidebar'>
                <span onClick={showDropdownSoftware}>Software</span>
                <div className={dropdownSoftware?"dropdown-content-active":"dropdown-content"}>
                    <Link to="/software-pos-advance-chile">POS Advance</Link>
                    <Link to="/erp-software-dimasoft-gestion-comercial-chile">Gestión Comercial v7.1</Link>
                    <Link to="/erp-software-dimasoft-gestion-ferreterias-chile">Gestión Ferreterias v7.1</Link>
                    <Link to="/software-contabilidad-dimasoft-chile/">Contabilidad v7.0</Link>
                    <Link to="/software-remuneraciones-dimasoft-chile">Remuneraciones v8.0</Link>
                    <Link to="/software-factura-electronica-dimasoft-chile">Factura Electrónica</Link>
                    <Link to="/boleta-electronica-dimasoft-chile">Boleta Electrónica</Link>
                    <Link to="/software-e-commerce-erp-chile">E-Commerce</Link>
                </div>
            </div>
            <div className='dropdown-sidebar'>
                <span onClick={showDropdownServices}>Servicios</span>
                <div className={dropdownServices?"dropdown-content-active":"dropdown-content"}>
                    <Link to="/capacitaciones-dimasoft-chile">Capacitaciones</Link>
                    <Link to="/servicios-ti-dimasoft-chile">Servicios TI</Link>
                    <Link to="/servicio-contable-y-auditoria">Servicio Contable y Auditoria</Link>
                </div>
            </div>
            <a href="https://tienda.dimasoft.cl/">Tienda</a>
            <Link to="/clientes-dimasoft">Clientes</Link>
            {/* <Link to="/distribuidores-dimasoft" >Distribuidores</Link> */}
            <Link to="/contacto">Contacto</Link>
        </div>
    </div>
}